import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export default function Categoryv2() {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [editingCategory, setEditingCategory] = useState(null); // To track the category being edited
  const [isDarkMode, setIsDarkMode] = useState(true); // Toggle for dark/light mode

  // Fetch all categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://api.gadgetfobia.com/api/v2/product/categories"
        );
        setCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching categories:", error.message);
        toast.error("Failed to fetch categories.");
      }
    };

    fetchCategories();
  }, []);

  // Handle form submission for adding or updating a category
  const handleCategorySubmit = async (e) => {
    e.preventDefault();
    if (!newCategory.trim()) {
      toast.error("Category name cannot be empty.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      if (editingCategory) {
        // Update existing category
        const response = await axios.put(
          `https://api.gadgetfobia.com/api/v2/product/categories/${editingCategory._id}`,
          {
            category: newCategory,
          }
        );
        setCategories((prev) =>
          prev.map((cat) =>
            cat._id === editingCategory._id ? response.data.data : cat
          )
        );
        setEditingCategory(null); // Reset editing mode
        toast.success("Category updated successfully!");
      } else {
        // Add new category
        const response = await axios.post(
          "https://api.gadgetfobia.com/api/v2/product/categories",
          {
            category: newCategory,
          }
        );
        setCategories((prev) => [response.data.data, ...prev]);
        toast.success("Category added successfully!");
      }
      setNewCategory(""); // Clear the input field
    } catch (error) {
      console.error("Error adding/updating category:", error.message);
      setError("Failed to process category. Please try again.");
      toast.error("Failed to process category. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle deleting a category
  const handleDeleteCategory = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      setLoading(true);
      try {
        const accessToken = localStorage.getItem("adminaccessToken");
        if (!accessToken) {
          toast.error("Access token is missing. Please log in again.");
          setLoading(false);
          return;
        }

        await axios.delete(
          `https://api.gadgetfobia.com/api/v2/product/categories/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Include the token here
            },
          }
        );
        setCategories((prev) => prev.filter((cat) => cat._id !== id)); // Remove from list
        toast.success("Category deleted successfully!");
      } catch (error) {
        console.error("Error deleting category:", error.message);
        setError("Failed to delete category. Please try again.");
        toast.error("Failed to delete category. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  // Handle editing a category
  const handleEditCategory = (category) => {
    setNewCategory(category.category);
    setEditingCategory(category);
  };

  // Toggle Dark/Light Mode
  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  // Dynamic styles based on theme
  const themeStyles = {
    backgroundColor: isDarkMode ? "#333" : "#fff",
    color: isDarkMode ? "#fff" : "#000",
  };

  return (
    <Layout>
      <div className="container-fluid" style={themeStyles}>
        <div className="d-flex justify-content-end align-items-center mb-3">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="themeSwitch"
              checked={isDarkMode} // Sync with theme state
              onChange={toggleTheme} // Handle theme toggle
            />
            <label
              className={`form-check-label ms-2 ${
                isDarkMode ? "text-white" : "text-dark"
              }`}
              htmlFor="themeSwitch"
            >
              {isDarkMode ? "Disable Dark Mode" : "Enable Dark Mode"}
            </label>
          </div>
        </div>

        {/*  */}
        <div>
          <h3>{editingCategory ? "Edit Category" : "Add New Category"}</h3>
          <form onSubmit={handleCategorySubmit} className="form-inline">
            <div className="mb-3">
              <input
                type="text"
                id="categoryName"
                className="form-control"
                placeholder="Enter category name"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                style={{
                  backgroundColor: isDarkMode ? "black" : "white",
                  color: isDarkMode ? "white" : "black",
                  border: `1px solid ${isDarkMode ? "white" : "black"}`,
                }}
              />
              <button
                type="submit"
                className="btn btn-success"
                disabled={loading}
              >
                {loading
                  ? editingCategory
                    ? "Updating..."
                    : "Saving..."
                  : editingCategory
                  ? "Update Category"
                  : "Save"}
              </button>
            </div>
          </form>
          {error && <p className="text-danger mt-2">{error}</p>}
        </div>

        {/* Display Categories in Rows with Responsive Grid */}
        <div className="mb-4">
          <h3>
            All Categories{" "}
            <span style={{ fontSize: "20px" }}>({categories.length})</span>
          </h3>
          <div
            className="row"
            style={{
              maxHeight: "500px",
              overflowY: "auto",
            }}
          >
            {categories.map((category) => (
              <div
                key={category._id}
                className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                <Link
                  to={`/admin/categoryv2/brand?q=${category.category}`}
                  className={`card m-2 ${isDarkMode ? "bg-dark" : "bg-light"}`}
                  style={{
                    textDecoration: "none",
                    color: isDarkMode ? "white" : "black",
                  }}
                >
                  <p className="m-3">{category.category}</p>
                </Link>
                <button
                  className="btn btn-warning btn-sm"
                  onClick={() => handleEditCategory(category)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDeleteCategory(category._id)}
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        theme={isDarkMode ? "dark" : "light"}
      />
    </Layout>
  );
}
