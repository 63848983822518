import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Layout from "./Layout";

export default function ProductOverviewv2() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); // Get query parameters
  const id = queryParams.get("id"); // Extract `id` from query params

  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) {
      setError("No product ID provided");
      setLoading(false);
      return;
    }

    const fetchProductData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.gadgetfobia.com/api/v2/product/products/${id}`
        ); // Replace with actual API endpoint
        setProductData(response.data.data); // Access the product data from `data.data`
      } catch (err) {
        setError("Failed to fetch product data");
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [id]);

  if (loading) {
    return (
      <Layout>
        <div className="text-center mt-4">Loading...</div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="text-center mt-4 text-danger">Error: {error}</div>
      </Layout>
    );
  }

  if (!productData) {
    return (
      <Layout>
        <div className="text-center mt-4">No product data found</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container mt-4">
        <h2 className="text-center mb-4">
          {productData.model_name || "Product Overview"}
        </h2>

        {/* Row with Image and Slider Images */}
        <div className="row mb-4">
          {/* Single Image (img) */}
          <div className="col-12 col-md-4 mb-3">
            <h5>Primary Image</h5>
            {productData.img ? (
              <img
                src={productData.img}
                alt="Primary Product"
                className="img-fluid rounded shadow"
                style={{
                  maxHeight: "400px",
                  objectFit: "contain",
                  width: "80%",
                }}
              />
            ) : (
              <p className="text-muted">Primary image not available</p>
            )}
          </div>

          {/* Slider Images (sliderImg) */}
          <div className="col-12 col-md-8">
            <h5>Additional Images</h5>
            {productData.sliderImg?.length > 0 ? (
              <div className="row">
                {productData.sliderImg.map((image, index) => (
                  <div key={index} className="col-3 col-lg-3">
                    <img
                      src={image}
                      alt={`Slider ${index + 1}`}
                      className="img-fluid rounded shadow"
                      style={{
                        maxHeight: "200px",
                        objectFit: "contain",
                        width: "100%",
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-muted">No additional images available</p>
            )}
          </div>
        </div>

        {/* Product Details in a Compact Table */}
        <div className="card p-3">
          <h4>Product Details</h4>
          <div className="table-responsive ">
            <table className="table table-sm table-bordered text-center ">
              <tbody>
                {Object.entries(productData).map(([key, value]) => {
                  // Skip the sliderImg and img fields as they are handled separately
                  if (key === "sliderImg" || key === "img") return null;

                  // Format arrays (e.g., pros, cons) or nested objects
                  const displayValue = Array.isArray(value) ? (
                    <ul className="list-unstyled mb-0 ">
                      {value.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  ) : typeof value === "object" && value !== null ? (
                    JSON.stringify(value, null, 2)
                  ) : (
                    value || "N/A"
                  );

                  return (
                    <tr key={key}>
                      <th style={{ whiteSpace: "nowrap", fontSize: "12px" }}>
                        {key.replace(/_/g, " ").toUpperCase()}
                      </th>
                      <td style={{ fontSize: "12px" }}>{displayValue}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}
