import React, { useState, useEffect } from "react";
import {
  FaMobileAlt,
  FaLaptop,
  FaCamera,
  FaHeadphones,
  FaGamepad,
  FaRegClock,
  FaTv,
  FaTabletAlt,
  FaWifi,
  FaPrint,
  FaExternalLinkAlt,
  FaSdCard,
  FaVrCardboard,
  FaClipboardList,
  FaCpanel,
  FaMouse,
  FaSpeakerDeck,
  FaWatchmanMonitoring,
  FaStopwatch,
  FaLock,
} from "react-icons/fa";
import {
  GiChipsBag,
  GiProcessor,
  GiSpeaker,
  GiSunglasses,
  GiWatch,
} from "react-icons/gi";
import { FaComputer, FaKeyboard, FaUserLock } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { getBrandData } from "../../../Service/Api"; // Import your API call function

const categoriesConfig = {
  smartTv: { id: 1, name: "Smart TV", icon: <FaTv /> },
  mobiles: { id: 2, name: "mobiles", icon: <FaMobileAlt /> },
  tablets: { id: 3, name: "Tablets", icon: <FaTabletAlt /> },
  routers: { id: 4, name: "Router", icon: <FaWifi /> },
  printers: { id: 5, name: "Printers", icon: <FaPrint /> },
  earbuds: { id: 6, name: "Earphones", icon: <FaHeadphones /> },
  Extenders: { id: 7, name: "Extenders", icon: <FaExternalLinkAlt /> },
  laptop: { id: 8, name: "Laptops", icon: <FaLaptop /> },
  graphicCards: { id: 9, name: "Graphic Card", icon: <FaSdCard /> },
  motherboards: { id: 10, name: "Motherboard", icon: <GiChipsBag /> },
  Processors: { id: 11, name: "Processors", icon: <GiProcessor /> },
  computers: { id: 11, name: "Computers", icon: <FaComputer /> },
  mouses: { id: 11, name: "gaming mouses", icon: <FaMouse /> },
  smartglasses: { id: 11, name: "Smart Glass", icon: <GiSunglasses /> },
  gamingConsoles: { id: 11, name: "Gaming-Consoles", icon: <FaGamepad /> },
  speakers: { id: 11, name: "Speakers", icon: <GiSpeaker /> },
  vrHeadsets: { id: 11, name: "VR-Headsets", icon: <FaVrCardboard /> },
  KEYBOARD: { id: 11, name: "gaming keyboard", icon: <FaKeyboard /> },
  smartWatches: { id: 11, name: "Smart Watch", icon: <GiWatch /> },
  firewall: { id: 11, name: "Firewall", icon: <FaUserLock /> },

  // add more product category
};

const CategoryGrid = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [iconData, setIconData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCategory) {
      fetchBrandData(selectedCategory);
    }
  }, [selectedCategory]);

  const fetchBrandData = async (categoryKey) => {
    try {
      const category = categoriesConfig[categoryKey]?.name;
      if (category) {
        const data = await getBrandData(category);
        if (data.success === true) {
          setIconData(data.data);
        } else {
          console.error("Failed to fetch data");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const visibleCategories = Object.entries(categoriesConfig).slice(
    0,
    isVisible ? undefined : 16
  );

  // Function to handle category click
  const handleCategoryClick = (categoryKey) => {
    const categoryRoute = `/${categoriesConfig[categoryKey]?.name}`;
    navigate(categoryRoute); // Redirect to the category page
  };

  return (
    <div className="flex flex-col items-center w-[96%] m-auto border-t-2 mt-10">
      <h2 className="text-2xl text-center font-bold mb-4 mt-3">
        What do you want to Explore today?
      </h2>
      <div className="flex flex-row flex-wrap items-center justify-start w-[80%] ">
        {visibleCategories.map(([key, { icon, name }]) => (
          <button
            key={key}
            onClick={() => handleCategoryClick(key)}
            className={`flex flex-col items-center justify-center rounded-lg border border-gray-300 h-[110px] w-[110px] p-1 m-2 hover:scale-[+1.1] transition-all ease-in-out .9s hover:text-white hover:bg-[#0A6075]`}
          >
            <div className="text-2xl mb-2">{icon}</div>
            <span className="text-sm">{name}</span>
          </button>
        ))}
      </div>
      <hr />
      <button
        className="bg-[#0A6075] text-white my-2 px-3 py-2 rounded-lg hover:bg-[#064c5e]"
        onClick={() => setIsVisible(!isVisible)}
      >
        {isVisible ? "View Less" : "View More"}
      </button>
      <hr />
    </div>
  );
};

export default CategoryGrid;
