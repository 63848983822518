import api from "../apiHelper";

export const addNewProduct = async (formData) => {
  try {
    const response = await api.post(`api/v2/product/products`, formData);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
