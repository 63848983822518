import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Layout from "./Layout";

export default function ProductEditv2() {
  const { id } = useParams(); // Get product ID from the URL
  const [productData, setProductData] = useState({});
  const [pros, setPros] = useState([]);
  const [cons, setCons] = useState([]);
  const [newPro, setNewPro] = useState("");
  const [newCon, setNewCon] = useState("");
  const [newFieldKey, setNewFieldKey] = useState("");
  const [newFieldValue, setNewFieldValue] = useState("");
  const [image, setImage] = useState(null); // Current or uploaded image file
  const [imagePreview, setImagePreview] = useState(null); // For image preview
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const excludedFields = [
    "_id",
    "__v",
    "createdAt",
    "updatedAt",
    "img",
    "sliderImg",
    "id",
  ];

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(
          `https://api.gadgetfobia.com/api/v2/product/products/${id}`
        );
        const product = response.data.data;

        // Parse `pros` and `cons` if they are stringified JSON arrays
        const parsedPros = parseField(product.pros || []);
        const parsedCons = parseField(product.cons || []);

        setProductData(product);
        setPros(parsedPros);
        setCons(parsedCons);
        setImage(product.img || null); // Set the existing image
        setImagePreview(product.img || null); // Set preview for existing image
      } catch (err) {
        toast.error("Failed to load product details.");
        setError(err.response ? err.response.data.message : err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [id]);

  const parseField = (field) => {
    if (Array.isArray(field)) {
      return field
        .map((item) => {
          try {
            return JSON.parse(item);
          } catch {
            return item; // Return as-is if parsing fails
          }
        })
        .flat();
    }
    return field;
  };

  const handleFieldChange = (key, value) => {
    setProductData({ ...productData, [key]: value });
  };

  const handleAddField = () => {
    if (newFieldKey.trim() && newFieldValue.trim()) {
      setProductData({ ...productData, [newFieldKey]: newFieldValue });
      setNewFieldKey("");
      setNewFieldValue("");
      toast.success("New field added successfully!");
    } else {
      toast.error("Both Key and Value are required.");
    }
  };

  const handleAddPro = () => {
    if (newPro.trim() !== "") {
      setPros([...pros, newPro.trim()]);
      setNewPro("");
    } else {
      toast.error("Please enter a valid Pro.");
    }
  };

  const handleDeletePro = (index) => {
    const updatedPros = pros.filter((_, i) => i !== index);
    setPros(updatedPros);
    toast.success("Pro deleted successfully.");
  };

  const handleAddCon = () => {
    if (newCon.trim() !== "") {
      setCons([...cons, newCon.trim()]);
      setNewCon("");
    } else {
      toast.error("Please enter a valid Con.");
    }
  };

  const handleDeleteCon = (index) => {
    const updatedCons = cons.filter((_, i) => i !== index);
    setCons(updatedCons);
    toast.success("Con deleted successfully.");
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file); // Create a preview URL
      setImage(file); // Set the new file
      setImagePreview(previewUrl); // Set the preview for UI
      toast.success("New image selected!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      // Append all product data fields to form data
      Object.entries(productData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // Append pros and cons as JSON strings
      formData.append("pros", JSON.stringify(pros));
      formData.append("cons", JSON.stringify(cons));

      // Append new image if uploaded
      if (image instanceof File) {
        formData.append("img", image);
      }

      const response = await axios.put(
        `https://api.gadgetfobia.com/api/v2/product/products/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        toast.success("Product updated successfully!");
      } else {
        toast.error("Failed to update product. Please try again.");
      }
    } catch (error) {
      toast.error("Something went wrong while updating the product.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="container py-4">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="card shadow-sm">
              <div className="card-header bg-info text-white text-center">
                Edit Product
              </div>
              <div className="card-body bg-dark">
                {loading && (
                  <p className="text-center">Loading product details...</p>
                )}
                {error && (
                  <p className="text-danger text-center">Error: {error}</p>
                )}

                {!loading && !error && (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-6">
                        {imagePreview ? (
                          <img
                            src={imagePreview}
                            alt="Preview"
                            className="img-fluid"
                            style={{ maxHeight: "200px" }}
                          />
                        ) : (
                          <p className="text-muted">No image available</p>
                        )}
                      </div>
                      <div className="col-6">
                        {" "}
                        <label className="form-label  text-light">
                          Upload New Image
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          className="form-control"
                        />
                      </div>
                    </div>
                    {/* Dynamic Fields */}
                    <div className="row">
                      {Object.entries(productData).map(
                        ([key, value]) =>
                          !excludedFields.includes(key) &&
                          key !== "pros" &&
                          key !== "cons" && (
                            <div
                              className="col-12 col-md-6 col-lg-4 mb-3"
                              key={key}
                            >
                              <label className="form-label text-light">
                                {key}
                              </label>
                              <input
                                type="text"
                                value={value}
                                onChange={(e) =>
                                  handleFieldChange(key, e.target.value)
                                }
                                className="form-control form-control-sm"
                              />
                            </div>
                          )
                      )}
                    </div>

                    {/* Add New Field */}
                    <div className="mb-3">
                      <label className="form-label  text-light">
                        Add New Field
                      </label>
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            value={newFieldKey}
                            onChange={(e) => setNewFieldKey(e.target.value)}
                            placeholder="Field Key"
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            value={newFieldValue}
                            onChange={(e) => setNewFieldValue(e.target.value)}
                            placeholder="Field Value"
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-success btn-sm mt-2"
                        onClick={handleAddField}
                      >
                        Add Field
                      </button>
                    </div>

                    {/* Pros Section */}
                    <div className="mb-3">
                      <h3 className=" text-light">Pros</h3>
                      <ul className="list-group mb-2">
                        {pros.map((pro, index) => (
                          <li
                            className="list-group-item d-flex justify-content-between"
                            key={index}
                          >
                            {pro}
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() => handleDeletePro(index)}
                            >
                              Delete
                            </button>
                          </li>
                        ))}
                      </ul>
                      <input
                        type="text"
                        value={newPro}
                        onChange={(e) => setNewPro(e.target.value)}
                        placeholder="Add a Pro"
                        className="form-control form-control-sm"
                      />
                      <button
                        type="button"
                        className="btn btn-success btn-sm mt-2"
                        onClick={handleAddPro}
                      >
                        Add Pro
                      </button>
                    </div>

                    {/* Cons Section */}
                    <div className="mb-3">
                      <h3 className=" text-light">Cons</h3>
                      <ul className="list-group mb-2">
                        {cons.map((con, index) => (
                          <li
                            className="list-group-item d-flex justify-content-between"
                            key={index}
                          >
                            {con}
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() => handleDeleteCon(index)}
                            >
                              Delete
                            </button>
                          </li>
                        ))}
                      </ul>
                      <input
                        type="text"
                        value={newCon}
                        onChange={(e) => setNewCon(e.target.value)}
                        placeholder="Add a Con"
                        className="form-control form-control-sm"
                      />
                      <button
                        type="button"
                        className="btn btn-success btn-sm mt-2"
                        onClick={handleAddCon}
                      >
                        Add Con
                      </button>
                    </div>

                    {/* Submit Button */}
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm mt-4 w-100"
                      disabled={loading}
                    >
                      {loading ? "Saving Changes..." : "Save Changes"}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
