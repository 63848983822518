import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSingleProductData } from "../../Service/Api";
import { addToWishlist } from "../../Service/wishlistService";
import ReviewModal from "../../Components/Comment/ReviewModal";
import { toast, ToastContainer } from "react-toastify";
import FAQ from "../FAQ.jsx"; // Update the path based on the location of your FAQ component
import UsageTips from "../UsageTips.jsx"; // Import the UsageTips component
import PrinterCompatibility from "../PrinterCompatibilty.jsx";
import { faqs, usageTips, categoryData } from "../SpecificationData.js";
import { addToCompare } from "../../Feature/store/compareActions.js";
import ComparisonPopup from "../../Feature/Compare/ComparisonPopup.jsx";
import Pros from "./Pros&cons/Pros.js";
import TroubleSection from "../../Components/Troubleshoot/TroubleSection.js";
import { Helmet } from "react-helmet"; // Import react-helmet
import ProductDetailModal from "./ProductDetailModal.jsx";
import { motion } from "framer-motion";
import { FaArrowAltCircleRight, FaArrowRight, FaHeart } from "react-icons/fa";
import DescriptionTable from "../../Components/product description/DescriptionTable.jsx";
import SliderImagePreviewv2 from "./v2/SliderImagePreviewv2.jsx";

// Function to convert HTML string to plain text
const stripHTML = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const ProductDetails = () => {
  const navigate = useNavigate();
  const { category: rawCategory, brand, slug } = useParams();

  // const category = rawCategory ? rawCategory.toUpperCase() : "";

  const category = rawCategory || "";

  const acceptableBrands = [
    "XEROX",
    "BROTHER",
    "EPSON",
    "CANON",
    "HP",
    "SAMSUNG",
    "LINKSYS",
    "NETGEAR",
    "TP-LINK",
    "HUAWEI",
    "iBall",
    "MSI",
    "Portronics",
    "D-Link",
    "Mercusys",
  ];

  // States for loading, data, error, and popup visibility
  const [data, setData] = useState(null);
  const dataa = data;
  console.log(data);
  const [loading, setLoading] = useState(true); // New loading state
  const [error, setError] = useState(null); // New error state
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();

  // const test =  dangerouslySetInnerHTML={{ __html: data.readMore}}

  const compareItems = useSelector(
    (state) => state.compare?.compareItems || []
  );
  const [id, setId] = useState([]);

  useEffect(() => {
    const storedId = JSON.parse(localStorage.getItem("id"));
    if (storedId) {
      setId(storedId);
    }
  }, []);

  // Fetch product data from API
  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const responseData = await getSingleProductData(category, brand, slug);
        if (responseData && responseData.status === "success") {
          setData(responseData.data);
        } else {
          throw new Error(
            responseData.message || "Error fetching product data"
          );
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchDataFromApi();
  }, [category, brand, slug]);

  // Handle comparison logic
  const handleCompare = () => {
    if (data) {
      setShowPopup(true);
      const isItemInCompare = compareItems.some(
        (item) => item.slug === data.slug
      );

      if (!isItemInCompare) {
        dispatch(addToCompare(data));
      } else {
        toast.info("Item is already in the comparison list.");
      }
    }
  };

  // Handle adding to wishlist logic
  async function handleAddToWishlist(product_Id, slug) {
    try {
      if (!product_Id || !slug) {
        toast.error("Product information is missing.", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      const token = localStorage.getItem("accessToken");
      if (!token) {
        toast.info("Please log in to add products to your wishlist.", {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/login");
        return;
      }

      const response = await addToWishlist(token, product_Id, slug);

      if (response.statusCode === 409) {
        toast.warn(response.message, { position: toast.POSITION.TOP_CENTER });
      } else if (response.statusCode === 200) {
        toast.success(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(response.message || "An error occurred.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
      toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
    }
  }

  // Page scroll to top when rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const variant = {
    visible: { scale: 1 },
    hidden: { scale: 0 },
  };

  // Find category and brand match in categoryData
  const findCategoryByCategoryAndBrand = (categoryData, category, brand) => {
    return categoryData.find(
      (item) => item.category === category && item.brand === brand
    );
  };

  const categoryInfo = findCategoryByCategoryAndBrand(
    categoryData,
    category,
    brand
  );

  // Helper function for valid meta value
  const getValidMetaValue = (value, fallback) => {
    // Ensure that the value is a string and it's not a symbol or other type
    return typeof value === "string" && value.trim() !== "" ? value : fallback;
  };

  // Helper function to generate canonical URL based on product data
  const generateCanonicalURL = () => {
    const baseUrl = "https://gadgetfobia.com"; // Your base website URL
    if (data && slug) {
      return `${baseUrl}/${category.toLowerCase()}/${brand.toLowerCase()}/${slug}`;
    }
    return baseUrl; // Fallback in case product data is missing
  };

  // useEffect(() => {
  //   console.log("metaTitle:", data?.metaTitle);
  //   console.log("metaDescription:", data?.metaDescription);
  //   console.log("metaKeywords:", data?.metaKeywords);
  // }, [data]);

  // Show loading indicator
  if (loading) {
    return <div>Loading...</div>; // You can replace this with a spinner or more detailed loading UI
  }

  // Show error if there's a problem fetching the data
  if (error) {
    return <div>Error: {error}</div>; // Show the error message to the user
  }

  // Assuming 'data' contains the readMore property
  const truncatedReadMore = data
    ? stripHTML(data.readMore).split(" ").slice(0, 20).join(" ") + "..."
    : "";

  // Full page renders after data is fetched
  return (
    <motion.div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      {/* Dynamic Helmet Meta Tags */}
      {data && data.metaTitle && data.metaDescription && data.metaKeywords && (
        <Helmet>
          <title>
            {getValidMetaValue(
              data.metaTitle,
              `${data.model_name} - Check ${data.brand} Complete Details, Full Comparison | gadgetfobia.com`
            )}
          </title>
          <meta
            name="description"
            content={getValidMetaValue(
              data.metaDescription,
              `${data.model_name} Compare Here: Check ${data.model_name}Specs and Reviews. Compare ${data.model_name} 360 Compare | gadgetfobia.com`
            )}
          />

          <meta
            name="keywords"
            content={getValidMetaValue(
              data.metaKeywords,
              `${data.model_name}, ${data.brand}, ${data.category}`
            )}
          />
          {/* Canonical Tag */}
          <link rel="canonical" href={generateCanonicalURL()} />
        </Helmet>
      )}

      <motion.div className="max-w-container mt-10 mx-auto px-1 ">
        <div className="xl:-mt-10 -mt-7 "></div>
        {data && (
          <div className="flex flex-wrap justify-center items-center w-full  rounded-md">
            <div className="flex flex-wrap justify-evenly items-center w-[90%] p-1">
              <div className="h-full w-[30rem] rounded-lg bg-white overflow-hidden border-grey-500 border flex items-center justify-center">
                <img
                  className="spec-img w-full h-full "
                  src={data.img}
                  alt={data.img}
                />
              </div>
              <div className="w-full md:w-1/2 h-full">
                <div className="name-brand text-start p-2">
                  {data && (
                    <h1 className="p-1 text-start text-2xl text-[#0A6075] font-extrabold">
                      {data.model_name}
                    </h1>
                  )}
                </div>
                <div className="readmore-div md:p-2 text-start">
                  <div className="flex flex-wrap p-1 w-[100%] m-auto items-start justify-center">
                    {data && <p>{truncatedReadMore}</p>}
                    <ProductDetailModal
                      name={data.model_name}
                      readmore={data.readMore}
                    />
                  </div>
                </div>
                {/* buttons */}
                <div className="flex flex-wrap w-full justify-around items-center ">
                  <div
                    onClick={handleCompare}
                    className="sm:w-1/3 w-1/2 flex justify-center items-center cursor-pointer rounded-md p-2 m-1  border border-[#0A6075] hover:bg-[#064c5e] text-center text-white bg-[#0A6075]">
                    <button className=" p-[4px] md:text-md text-sm ">
                      Compare
                    </button>
                    <FaArrowRight />
                  </div>
                  <div
                    onClick={() => handleAddToWishlist(data._id, data.slug)}
                    className="w-1/3 flex justify-center items-center cursor-pointer rounded-md p-2 m-1  border border-[#0A6075] hover:bg-[#064c5e] text-center text-white bg-[#0A6075]">
                    <button className=" p-[4px] md:text-md sm:text-sm text-xs ">
                      Favourite
                    </button>
                    <FaHeart />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Render table for dynamic properties */}
        {/* description table */}
        {/* slider image preview v2 */}
        <SliderImagePreviewv2 sliderImages={data?.sliderImg} />
        <DescriptionTable />
      </motion.div>
      {/* pros and cons section */}
      <div className="p-2">
        <Pros category={category} brand={brand} slug={slug} />
      </div>

      {/* Accordion FAQ section/ */}
      <FAQ category={category} faqs={faqs} />

      <div>
        {usageTips.map((tipCategory) =>
          tipCategory.category === category ? (
            <UsageTips key={category} tips={tipCategory.tips} />
          ) : null
        )}
      </div>
      {categoryInfo && (
        <PrinterCompatibility
          category={category}
          brand={brand}
          categoryData={categoryData}
        />
      )}

      {data && acceptableBrands.includes(brand.toUpperCase()) && (
        <TroubleSection
          name={data.model_name}
          img={data.img}
          id={id}
          category={category}
          brand={brand}
        />
      )}

      <ReviewModal productId={data._id} />
      <ToastContainer />
      {showPopup && <ComparisonPopup popup={() => setShowPopup(false)} />}
    </motion.div>
  );
};

export default ProductDetails;
