import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  adminProfile,
  adminProfileEdit,
  adminChangePassword,
} from "../../Service/adminService"; // Separate APIs for profile update and password change
import Layout from "./Layout";

export default function Profilev2() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [editMode, setEditMode] = useState(false); // To toggle profile edit mode
  const [passwordMode, setPasswordMode] = useState(false); // To toggle password change mode
  const [error, setError] = useState(null);

  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
  }); // Form values for profile edit

  const [passwordValues, setPasswordValues] = useState({
    currentPassword: "",
    newPassword: "",
  }); // Form values for password change

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const accessToken = localStorage.getItem("adminaccessToken");

        if (!accessToken) {
          navigate("/admin/auth"); // Redirect to login if no access token
          return;
        }

        // Fetch profile data
        const response = await adminProfile(accessToken);

        if (response.success) {
          setProfileData(response.data);
          setFormValues({
            fullName: response.data.fullName || "",
            email: response.data.email || "",
          });
        } else {
          navigate("/admin/auth"); // Redirect on failure
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setError("An error occurred while fetching profile.");
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editMode) {
      setFormValues({ ...formValues, [name]: value });
    } else if (passwordMode) {
      setPasswordValues({ ...passwordValues, [name]: value });
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const accessToken = localStorage.getItem("adminaccessToken");

      if (!accessToken) {
        navigate("/admin/auth");
        return;
      }

      const response = await adminProfileEdit(accessToken, formValues); // Profile update API

      if (response.success) {
        setProfileData((prevData) => ({
          ...prevData,
          fullName: formValues.fullName,
          email: formValues.email,
        }));
        setEditMode(false); // Exit profile edit mode
        alert("Profile updated successfully.");
      } else {
        alert(response.message || "Failed to update profile.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("An error occurred while updating profile.");
    }
  };

  const handleChangePassword = async () => {
    try {
      const accessToken = localStorage.getItem("adminaccessToken");

      if (!accessToken) {
        navigate("/admin/auth");
        return;
      }

      const response = await adminChangePassword(accessToken, passwordValues); // Call the updated API

      if (response.success) {
        alert("Password updated successfully.");
      } else {
        alert(response.message || "Failed to change password.");
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      alert("An unexpected error occurred while changing password.");
    }
  };

  if (error) {
    return (
      <Layout>
        <div className="text-center mt-5 text-danger">
          <h4>{error}</h4>
        </div>
      </Layout>
    );
  }

  if (!profileData) {
    return (
      <Layout>
        <div className="text-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6">
            <div
              className="card shadow-lg border-0 text-white"
              style={{ backgroundColor: "#343a40" }}>
              {!editMode && !passwordMode ? (
                <>
                  {/* View Mode */}
                  <div
                    className="card-header text-center"
                    style={{
                      backgroundColor: "#212529",
                      borderBottom: "2px solid #495057",
                    }}>
                    <img
                      src={
                        profileData.avatar ||
                        "https://via.placeholder.com/150?text=User+Avatar"
                      }
                      alt="User"
                      className="rounded-circle mb-3"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        border: "4px solid #495057",
                      }}
                    />
                    <h4 className="mb-0">{profileData.fullName || "N/A"}</h4>
                    <p className="mb-2 text-muted">
                      {profileData.role.charAt(0).toUpperCase() +
                        profileData.role.slice(1)}
                    </p>
                    <span
                      className={`badge ${
                        profileData.isEmailVerified ? "bg-success" : "bg-danger"
                      }`}>
                      {profileData.isEmailVerified
                        ? "Verified"
                        : "Not Verified"}
                    </span>
                  </div>

                  <div className="card-body h-50">
                    <ul className="list-group m-0">
                      <li
                        className="list-group-item text-white"
                        style={{ backgroundColor: "#343a40" }}>
                        <strong>Email:</strong> {profileData.email || "N/A"}
                      </li>
                      <li
                        className="list-group-item text-white"
                        style={{ backgroundColor: "#343a40" }}>
                        <strong>Active Status:</strong>{" "}
                        {profileData.isActive ? "Active" : "Inactive"}
                      </li>
                    </ul>
                  </div>

                  <div
                    className="card-footer text-center"
                    style={{ backgroundColor: "#212529" }}>
                    <button
                      className="btn btn-outline-light px-4 me-2"
                      onClick={() => setEditMode(true)}>
                      Edit Profile
                    </button>
                    <button
                      className="btn btn-outline-light px-4"
                      onClick={() => setPasswordMode(true)}>
                      Change Password
                    </button>
                  </div>
                </>
              ) : editMode ? (
                <>
                  {/* Edit Profile Mode */}
                  <div
                    className="card-body"
                    style={{ backgroundColor: "#343a40" }}>
                    <h4 className="text-center mb-3 text-white">
                      Edit Profile
                    </h4>
                    <form>
                      <div className="mb-3">
                        <label className="form-label text-white">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control bg-dark text-white border-0"
                          name="fullName"
                          value={formValues.fullName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-white">Email</label>
                        <input
                          type="email"
                          className="form-control bg-dark text-white border-0"
                          name="email"
                          value={formValues.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-outline-light"
                          onClick={() => setEditMode(false)}>
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleUpdateProfile}>
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              ) : (
                <>
                  {/* Change Password Mode */}
                  <div
                    className="card-body"
                    style={{ backgroundColor: "#343a40" }}>
                    <h4 className="text-center mb-3 text-white">
                      Change Password
                    </h4>
                    <form>
                      <div className="mb-3">
                        <label className="form-label text-white">
                          Current Password
                        </label>
                        <input
                          type="password"
                          className="form-control bg-dark text-white border-0"
                          name="currentPassword"
                          value={passwordValues.currentPassword}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-white">
                          New Password
                        </label>
                        <input
                          type="password"
                          className="form-control bg-dark text-white border-0"
                          name="newPassword"
                          value={passwordValues.newPassword}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-outline-light"
                          onClick={() => setPasswordMode(false)}>
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleChangePassword}>
                          Change Password
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
