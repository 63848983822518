import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Layout from "./Layout";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify styles
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Brand() {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("q"); // Extract the category query parameter

  const [brands, setBrands] = useState([]); // State for fetched brands
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(""); // State for errors
  const [totalCount, setTotalCount] = useState(0); // Total brands count
  const [loadingSubmit, setLoadingSubmit] = useState(false); // State for submit button loading

  const [brandName, setBrandName] = useState(""); // Brand name input
  const [image, setImage] = useState(null); // Image upload input
  const [metaTitle, setMetaTitle] = useState(""); // Meta title input
  const [metaDescription, setMetaDescription] = useState(""); // Meta description input
  const [metaKeywords, setMetaKeywords] = useState(""); // Meta keywords input
  const [editingBrand, setEditingBrand] = useState(null); // Editing state
  const navigate = useNavigate();
  const fileInputRef = useRef(null); // Reference to the file input

  // Fetch brands based on category
  useEffect(() => {
    const fetchBrands = async () => {
      if (!category) {
        setError("Category query parameter 'q' is missing.");
        setLoading(false);
        return;
      }

      setLoading(true);
      setError("");

      try {
        const response = await axios.get(
          `https://api.gadgetfobia.com/api/v2/product/brand?q=${category}`
        );

        if (response.data.success) {
          setBrands(response.data.data);
          setTotalCount(response.data.totalCount);
        } else {
          setBrands([]);
          setTotalCount(0);
          setError(response.data.message || "No brands found.");
        }
      } catch (error) {
        console.error("Error fetching brands:", error.message);
        setError(error.response?.data?.message || "Failed to fetch brands.");
        setBrands([]);
        setTotalCount(0);
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [category]);

  // Handle form submission (Add/Update)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true); // Start loading spinner

    try {
      const formData = new FormData();

      formData.append("brand", brandName);
      formData.append("category", category);

      if (image) {
        formData.append("imgUrl", image);
      }

      // Add optional fields
      if (metaTitle) formData.append("metaTitle", metaTitle);
      if (metaDescription) formData.append("metaDescription", metaDescription);
      if (metaKeywords) formData.append("metaKeywords", metaKeywords);

      let response;

      if (editingBrand) {
        // Update existing brand
        response = await axios.put(
          `https://api.gadgetfobia.com/api/v2/product/brand/${editingBrand._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Brand updated successfully!");
        setBrands((prevBrands) =>
          prevBrands.map((brand) =>
            brand._id === editingBrand._id ? response.data.data : brand
          )
        );
      } else {
        // Add new brand
        response = await axios.post(
          "https://api.gadgetfobia.com/api/v2/product/brand",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Brand added successfully!");
        setBrands((prevBrands) => [response.data.data, ...prevBrands]);
        setTotalCount((prevCount) => prevCount + 1);
      }

      // Reset form fields
      setBrandName("");
      setMetaTitle("");
      setMetaDescription("");
      setMetaKeywords("");
      setEditingBrand(null);
      setImage(null);

      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    } catch (error) {
      console.error("Error saving brand:", error.message);
      toast.error(error.response.data.message);
    } finally {
      setLoadingSubmit(false); // Stop loading spinner
    }
  };

  // Handle deleting a brand
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this brand?")) {
      try {
        const accessToken = localStorage.getItem("adminaccessToken");
        if (!accessToken) {
          toast.error("Access token is missing. Please log in again.");
          setLoading(false);
          return;
        }
        await axios.delete(
          `https://api.gadgetfobia.com/api/v2/product/brand/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Include the token here
            },
          }
        );
        setBrands((prevBrands) =>
          prevBrands.filter((brand) => brand._id !== id)
        );
        setTotalCount((prevCount) => prevCount - 1);
        toast.success("Brand deleted successfully!");
      } catch (error) {
        console.error("Error deleting brand:", error.message);
        toast.error("Failed to delete brand. Please try again.");
      }
    }
  };

  // Handle editing a brand
  const handleEdit = (brand) => {
    setEditingBrand(brand);
    setBrandName(brand.brand);
    setMetaTitle(brand.metaTitle || "");
    setMetaDescription(brand.metaDescription || "");
    setMetaKeywords(brand.metaKeywords || "");
    setImage(null); // Image upload optional during update
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear the file input when editing
    }
  };

  const navagateToNextPage = (category, brand) => {
    navigate(
      `/admin/categoryv2/brand/product?category=${category}&brand=${brand}`
    );
  };

  return (
    <Layout>
      <div className="container-fluid bg-dark text-white p-4">
        <h3 className="mb-4">
          {category ? `Category: ${category}` : "No category selected"}
        </h3>
        {/* Form to add/update a brand */}
        <form onSubmit={handleSubmit} className="mb-4">
          <div className="row">
            <div className="col-md-6 mb-2">
              <label className="form-label">Category</label>
              <input
                type="text"
                className="form-control form-control-sm bg-dark text-white"
                value={category}
                disabled
              />
            </div>
            <div className="col-md-6 mb-2">
              <label className="form-label">Brand Name</label>
              <input
                type="text"
                className="form-control form-control-sm bg-dark text-white"
                value={brandName}
                onChange={(e) => setBrandName(e.target.value)}
                placeholder="Enter brand name"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-2">
              <label className="form-label">Upload Image</label>
              <input
                ref={fileInputRef} // Attach the ref to the file input
                type="file"
                className="form-control form-control-sm bg-dark text-white"
                onChange={(e) => setImage(e.target.files[0])}
                accept="image/*"
              />
              {image && (
                <small className="text-muted mt-1 d-block">
                  Selected file: <strong>{image.name}</strong>
                </small>
              )}
            </div>
            <div className="col-md-6 mb-2">
              <label className="form-label">Meta Title</label>
              <input
                type="text"
                className="form-control form-control-sm bg-dark text-white"
                value={metaTitle}
                onChange={(e) => setMetaTitle(e.target.value)}
                placeholder="Enter meta title (optional)"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-2">
              <label className="form-label">Meta Description</label>
              <textarea
                className="form-control form-control-sm bg-dark text-white"
                value={metaDescription}
                onChange={(e) => setMetaDescription(e.target.value)}
                placeholder="Enter meta description (optional)"
                rows={2}
              />
            </div>
            <div className="col-md-6 mb-2">
              <label className="form-label">Meta Keywords</label>
              <input
                type="text"
                className="form-control form-control-sm bg-dark text-white"
                value={metaKeywords}
                onChange={(e) => setMetaKeywords(e.target.value)}
                placeholder="Enter meta keywords (optional)"
              />
            </div>
          </div>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={loadingSubmit}
            >
              {loadingSubmit ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : editingBrand ? (
                "Update Brand"
              ) : (
                "Add Brand"
              )}
            </button>
          </div>
        </form>
        {/* Show error message */}
        {error && <p className="text-danger">{error}</p>}
        {/* Show brands in tabular format */}
        {!loading && !error && brands.length > 0 && (
          <table className="table table-dark table-striped">
            <thead>
              <tr>
                <th>Category</th>
                <th>Brand</th>
                <th>Image</th>
                <th>Meta Title</th>
                <th>Meta Description</th>
                <th>Meta Keywords</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {brands.map((brand) => (
                <tr key={brand._id}>
                  <td>{brand.category}</td>
                  <td
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => navagateToNextPage(category, brand.brand)}
                  >
                    {brand.brand}
                  </td>
                  <td>
                    <img
                      src={brand.imgUrl}
                      alt={brand.brand}
                      style={{ width: "100px", height: "auto" }}
                    />
                  </td>
                  <td>{brand.metaTitle || "-"}</td>
                  <td>{brand.metaDescription || "-"}</td>
                  <td>{brand.metaKeywords || "-"}</td>
                  <td>
                    <button
                      className="btn btn-warning btn-sm mr-2"
                      onClick={() => handleEdit(brand)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(brand._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {loading && <p>Loading...</p>} {/* Show loading indicator */}
      </div>
      <ToastContainer position="top-right" autoClose={3000} theme="dark" />
    </Layout>
  );
}
