import React, { useState } from "react";

export default function FlipkartStyleImagePreview({ sliderImages }) {
  // Initialize state hooks unconditionally
  const [activeImage, setActiveImage] = useState(sliderImages?.[0] || null); // Default to null if no images
  const [zoomStyle, setZoomStyle] = useState({});

  // Early return for empty or missing sliderImages
  if (!sliderImages || sliderImages.length === 0) {
    return null; // Return nothing if sliderImages is invalid
  }

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    setZoomStyle({
      backgroundImage: `url(${activeImage})`,
      backgroundPosition: `${x}% ${y}%`,
      backgroundSize: "500%",
    });
  };

  const handleMouseLeave = () => {
    setZoomStyle({});
  };

  return (
    <div className="container mt-5">
      <div className="row gx-5">
        {/* Thumbnails and Main Image Section */}
        <div className="col-12 col-md-6 d-flex flex-column align-items-center gap-4">
          {/* Thumbnails */}
          <div className="d-flex flex-wrap justify-content-center gap-3">
            {sliderImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                onMouseEnter={() => setActiveImage(image)}
                className={`border ${
                  activeImage === image
                    ? "border-primary shadow-sm"
                    : "border-secondary"
                }`}
                style={{
                  width: "60px",
                  height: "60px",
                  objectFit: "cover",
                  cursor: "pointer",
                  borderRadius: "5px",
                  transition: "transform 0.2s ease",
                }}
              />
            ))}
          </div>

          {/* Main Display */}
          <div
            className="border rounded shadow-sm"
            style={{
              position: "relative",
              width: "100%",
              maxWidth: "400px",
              height: "auto",
              overflow: "hidden",
              backgroundColor: "#f8f9fa",
            }}>
            <img
              src={activeImage}
              alt="Main Display"
              className="w-100 h-100"
              style={{
                objectFit: "contain",
              }}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            />
          </div>
        </div>

        {/* Zoom Preview Section */}
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
          <div
            aria-label="Zoomed Image Preview"
            className="border rounded shadow-sm"
            style={{
              width: "100%",
              maxWidth: "400px",
              height: "400px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              ...zoomStyle,
              backgroundColor: "#f8f9fa",
            }}
          />
        </div>
      </div>
    </div>
  );
}
