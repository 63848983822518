import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Layout from "./Layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

// Debounce function
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default function ProductListv2() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedImages, setSelectedImages] = useState({});
  const [selectedFiles, setSelectedFiles] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [jumpPage, setJumpPage] = useState("");
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    fetchProducts(currentPage, limit, searchQuery);
  }, [currentPage]);

  const fetchProducts = async (page, limit, search = "") => {
    if (search) {
      setIsSearching(true);
    } else {
      setLoading(true);
    }

    try {
      const response = await axios.get(
        `https://api.gadgetfobia.com/api/v2/product/stores`,
        {
          params: { page, limit, search },
        }
      );
      setProducts(response.data.data);
      setCount(response.data.totalCount);
      setFilteredProducts(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      setError("Failed to fetch products");
      toast.error("Failed to fetch products.");
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  const handleSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      setCurrentPage(1);
      fetchProducts(1, limit, query);
    }, 300),
    []
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleJumpToPage = () => {
    const page = parseInt(jumpPage, 10);
    if (!isNaN(page) && page > 0 && page <= totalPages) {
      setCurrentPage(page);
      setJumpPage("");
    } else {
      toast.warn("Invalid page number. Please enter a valid page.");
    }
  };

  const handleFileSelection = (productId, files) => {
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [productId]: files,
    }));
  };

  const uploadSelectedImages = async () => {
    const allSelectedFiles = Object.entries(selectedFiles).filter(
      ([_, files]) => files.length > 0
    );

    if (allSelectedFiles.length === 0) {
      toast.warn("No images selected for upload.");
      return;
    }

    setIsUploading(true);
    try {
      for (const [productId, files] of allSelectedFiles) {
        const formData = new FormData();
        for (const file of files) {
          formData.append("sliderImg", file);
        }

        const { data } = await axios.post(
          `https://api.gadgetfobia.com/api/v2/product/products/upload/${productId}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        if (data.success) {
          const uploadedImages = data.images;
          updateProductList(productId, {
            ...products.find((product) => product._id === productId),
            sliderImg: [
              ...products.find((product) => product._id === productId)
                .sliderImg,
              ...uploadedImages,
            ],
          });
          toast.success(`Images uploaded successfully.`);
        }
      }

      setSelectedFiles({});
    } catch (err) {
      toast.error("Failed to upload selected images.");
    } finally {
      setIsUploading(false);
    }
  };

  const deleteSelectedImages = async () => {
    const allSelectedImages = Object.entries(selectedImages).filter(
      ([_, images]) => images.length > 0
    );

    if (allSelectedImages.length === 0) {
      toast.warn("No images selected for deletion.");
      return;
    }

    if (!window.confirm("Are you sure you want to delete the selected images?"))
      return;
    const accessToken = localStorage.getItem("adminaccessToken");
    if (!accessToken) {
      toast.error("Access token is missing. Please log in again.");
      setLoading(false);
      return;
    }
    setIsDeleting(true);
    try {
      for (const [productId, imagesToDelete] of allSelectedImages) {
        const response = await axios.delete(
          `https://api.gadgetfobia.com/api/v2/product/products/delete/${productId}`,
          { data: { imageUrls: imagesToDelete } },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Include the token here
            },
          }
        );

        const updatedProduct = {
          ...products.find((product) => product._id === productId),
          sliderImg: response.data.updatedImages,
        };

        updateProductList(productId, updatedProduct);
        toast.success(`Images deleted successfully.`);
      }

      setSelectedImages({});
    } catch (err) {
      toast.error("Failed to delete selected images.");
    } finally {
      setIsDeleting(false);
    }
  };

  const updateProductList = (productId, updatedProduct) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product && product._id === productId ? updatedProduct : product
      )
    );
    setFilteredProducts((prevProducts) =>
      prevProducts.map((product) =>
        product && product._id === productId ? updatedProduct : product
      )
    );
  };

  const toggleImageSelection = (productId, imgUrl) => {
    setSelectedImages((prev) => {
      const currentSelection = prev[productId] || [];
      const updatedSelection = currentSelection.includes(imgUrl)
        ? currentSelection.filter((url) => url !== imgUrl)
        : [...currentSelection, imgUrl];
      return { ...prev, [productId]: updatedSelection };
    });
  };

  const isJumpButtonEnabled = jumpPage && parseInt(jumpPage, 10) > 0;
  const isUploadButtonEnabled = Object.values(selectedFiles).some(
    (files) => files && files.length > 0
  );
  const isDeleteButtonEnabled = Object.values(selectedImages).some(
    (images) => images && images.length > 0
  );

  const navigateToOverviewPage = (id) => {
    navigate(`/admin/products-list/overview?id=${id}`);
  };

  return (
    <Layout>
      <div className="container-fluid mt-4">
        {/* Controls Row */}
        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-4">
          <input
            type="text"
            className="form-control w-auto"
            placeholder="Search by Model Name"
            onChange={(e) => handleSearch(e.target.value)}
          />
          {isSearching && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          <div>
            <button
              className="btn btn-secondary btn-sm me-2"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-secondary btn-sm ms-2"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
          <div className="d-flex align-items-center">
            <input
              type="number"
              className="form-control form-control-sm"
              placeholder="Go to page"
              value={jumpPage}
              onChange={(e) => setJumpPage(e.target.value)}
              style={{ width: "100px", marginRight: "10px" }}
            />
            <button
              className="btn btn-primary btn-sm"
              onClick={handleJumpToPage}
              disabled={!isJumpButtonEnabled}
            >
              Go
            </button>
          </div>
          <div className="d-flex gap-2">
            <button
              className="btn btn-primary btn-sm"
              onClick={uploadSelectedImages}
              disabled={!isUploadButtonEnabled || isUploading}
            >
              {isUploading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-1"></span>
                  Uploading...
                </>
              ) : (
                "Upload Selected"
              )}
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={deleteSelectedImages}
              disabled={!isDeleteButtonEnabled || isDeleting}
            >
              {isDeleting ? (
                <>
                  <span className="spinner-border spinner-border-sm me-1"></span>
                  Deleting...
                </>
              ) : (
                "Delete Selected"
              )}
            </button>
          </div>
        </div>
        {/* Table */}
        <div
          className="table-responsive"
          style={{ maxHeight: "500px", overflowY: "auto" }}
        >
          <h2>
            Product List <span>({count})</span>
          </h2>
          <table className="table table-bordered table-hover">
            <thead
              className="thead-dark"
              style={{ position: "sticky", top: 0 }}
            >
              <tr>
                <th style={{ width: "80px" }}>S.No</th>
                <th>Created At</th>
                <th style={{ width: "auto" }}>Last Update</th>
                <th>Image</th>
                <th style={{ width: "200px" }}>Model Name</th>
                <th>Category</th>
                <th>Brand</th>
                <th>Slider Images</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product, index) => (
                <tr key={product._id}>
                  <td>{(currentPage - 1) * limit + index + 1}</td>
                  <td style={{ fontSize: "11px" }}>
                    {product?.createdAt
                      ? new Date(product.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "Not Available"}
                  </td>

                  <td style={{ fontSize: "11px" }}>
                    {new Date(product.updatedAt).toLocaleString("en-IN", {
                      timeZone: "Asia/Kolkata",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </td>
                  <td>
                    {product.img ? (
                      <img
                        src={product.img}
                        alt={product.model_name}
                        style={{ width: "50px", height: "50px" }}
                      />
                    ) : (
                      <span>No image available</span>
                    )}
                  </td>
                  <td
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => navigateToOverviewPage(product._id)}
                  >
                    {product.model_name || "N/A"}
                  </td>
                  <td>{product.category || "N/A"}</td>
                  <td>{product.brand || "N/A"}</td>
                  <td>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {Array.isArray(product.sliderImg) &&
                        product.sliderImg.map((imgUrl, imgIndex) => (
                          <div
                            key={imgIndex}
                            style={{
                              position: "relative",
                              margin: "5px",
                              display: "inline-block",
                            }}
                          >
                            <img
                              src={imgUrl}
                              alt={`Slider ${imgIndex + 1}`}
                              style={{
                                width: "30px",
                                height: "30px",
                              }}
                            />
                            <input
                              type="checkbox"
                              style={{
                                position: "absolute",
                                top: "5px",
                                left: "5px",
                              }}
                              checked={
                                selectedImages[product._id]?.includes(imgUrl) ||
                                false
                              }
                              onChange={() =>
                                toggleImageSelection(product._id, imgUrl)
                              }
                            />
                          </div>
                        ))}
                      <label
                        htmlFor={`fileUpload-${product._id}`}
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "#e9ecef",
                          border: "1px dashed #6c757d",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          margin: "5px",
                        }}
                      >
                        +
                      </label>
                      <input
                        id={`fileUpload-${product._id}`}
                        type="file"
                        style={{ display: "none" }}
                        multiple
                        onChange={(e) => {
                          const files = e.target.files;
                          if (files.length > 0)
                            handleFileSelection(product._id, files);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </Layout>
  );
}
