import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { adminAllCount } from "../../Service/adminService";
import { Link } from "react-router-dom";

export default function Dashboardv2() {
  const [counts, setCounts] = useState({
    products: 0,
    categories: 0,
    brands: 0,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await adminAllCount();
        setCounts({
          products: response.ProductCount || 0,
          categories: response.CategoryCount || 0,
          brands: response.BrandCount || 0,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  if (loading) {
    return (
      <Layout>
        <div className="text-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div
        className="container-fluid py-4 bg-dark text-dark"
        style={{ minHeight: "100vh" }}>
        <h1 className="text-center mb-4">Admin Dashboard</h1>

        <div className="row text-center">
          {/* Products */}
          <div className="col-md-4 mb-4">
            <div className="card shadow bg-dark text-dark">
              <div className="card-body bg-dark text-white">
                <h5 className="card-title">Total Products</h5>
                <Link to={"/admin/products-list"}>
                  {" "}
                  <h2 className="display-4">{counts.products}</h2>
                </Link>
              </div>
            </div>
          </div>

          {/* Categories */}
          <div className="col-md-4 mb-4">
            <div className="card shadow bg-dark text-dark ">
              <div className="card-body  bg-dark text-white">
                <h5 className="card-title">Total Categories</h5>
                <Link to={"/admin/categoryv2"}>
                  {" "}
                  <h2 className="display-4">{counts.categories}</h2>
                </Link>
              </div>
            </div>
          </div>

          {/* Brands */}
          <div className="col-md-4 mb-4">
            <div className="card shadow bg-dark text-dark">
              <div className="card-body  bg-dark text-white">
                <h5 className="card-title">Total Brands</h5>
                <h2 className="display-4">{counts.brands}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
