import api from "../Service/apiHelper";

export const adminAllCount = async () => {
  try {
    const response = await api.post(`api/v1/admin/dash-count`);
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminBrand = async (category) => {
  try {
    // const url = `api/v1/admin/brand`;

    // Construct the query parameters object
    const params = {};
    if (category) {
      params.category = category;
    }

    // Make the GET request with the query parameters
    const response = await api.get(`api/v1/admin/brand`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching brand data:", error);
    throw new Error("An error occurred while fetching brand data.");
  }
};
export const adminProduct = async (category, brand) => {
  try {
    const params = {};

    if (category) {
      params.category = category;
    }
    if (brand) {
      params.brand = brand;
    }

    const response = await api.get(`api/v1/admin/product`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching product data:", error);
    throw new Error("An error occurred while fetching product data.");
  }
};
export const adminLogin = async (email, password) => {
  try {
    const response = await api.post(`api/v1/admin/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminProfile = async (accessToken) => {
  try {
    const response = await api.get(`api/v1/admin/current-admin`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data; // Return the data from the response
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error fetching admin profile:", error);
    }
  }
};
export const adminProfileEdit = async (accessToken, formvalue) => {
  try {
    const response = await api.get(`-`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data; // Return the data from the response
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error fetching admin profile:", error);
    }
  }
};
export const adminChangePassword = async (accessToken, data) => {
  try {
    const response = await api.post(`api/v1/admin/change-password`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data; // Return the success response
  } catch (error) {
    if (error.response) {
      const contentType = error.response.headers["content-type"];

      if (contentType && contentType.includes("application/json")) {
        // Handle JSON response errors
        return error.response.data;
      } else if (contentType && contentType.includes("text/html")) {
        // Handle HTML response errors
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(
          error.response.data,
          "text/html"
        );
        const errorMessage =
          htmlDoc.querySelector("pre")?.innerText ||
          "An unexpected error occurred.";
        return {
          success: false,
          message: errorMessage.split("<br>")[0].trim(),
        }; // Extract only the main error
      }
    }

    console.error("Error changing admin password:", error);
    return { success: false, message: "An unexpected error occurred." }; // Fallback error
  }
};

export const adminLogout = async (token) => {
  try {
    // Configure request headers to include the bearer token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    };

    // Send a POST request to the backend logout endpoint with the configured headers
    const response = await api.post(`api/v1/admin/logout`, null, config);
    return response.data; // Return the response data
  } catch (error) {
    // If an error occurs, throw an error with a custom message
    throw new Error("Logout failed: " + error.message);
  }
};
export const adminUserList = async () => {
  try {
    // Send a POST request to the backend logout endpoint with the configured headers
    const response = await api.post(`api/v1/admin/adminUserList`);
    return response.data; // Return the response data
  } catch (error) {
    // If an error occurs, throw an error with a custom message
    throw new Error("Logout failed: " + error.message);
  }
};

// ##################################### admin auth ############################################
export const registeradmin = async (adminData) => {
  try {
    const response = await api.post(`api/v1/admin/register`, adminData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error registering admin:", error);
      throw new Error("An error occurred while registering the admin.");
    }
  }
};
export const loginadmin = async (adminData) => {
  try {
    const response = await api.post(`api/v1/admin/login`, adminData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      throw new Error("Error in Logging admin. Please try again later.");
    }
  }
};
export const currentadmin = async (token) => {
  try {
    const response = await api.get(`api/v1/admin/current-admin`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      throw new Error("An error occurred while fetching current admin.");
    }
  }
};
export const logoutadmin = async (token) => {
  try {
    // Configure request headers to include the bearer token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    };

    // Send a POST request to the backend logout endpoint with the configured headers
    const response = await api.post(`api/v1/admin/logout`, null, config);
    return response.data; // Return the response data
  } catch (error) {
    // If an error occurs, throw an error with a custom message
    throw new Error("Logout failed: " + error.message);
  }
};
