import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import AddProductv2 from "./AddProductv2";
import { toast } from "react-toastify";

export default function Productv2() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const category = queryParams.get("category");
  const brand = queryParams.get("brand");

  const [products, setProducts] = useState([]);
  const [count, setCount] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // Fetch products on mount or when query params change
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          "https://api.gadgetfobia.com/api/v2/product/products",
          {
            params: {
              category: category,
              brand: brand,
            },
          }
        );
        setProducts(response.data.data);
        setCount(response.data.totalCount);
      } catch (err) {
        setError(err.response ? err.response.data.message : err.message);
      } finally {
        setLoading(false);
      }
    };

    if (category && brand) {
      fetchData();
    }
  }, [category, brand]);

  // Handle Delete Product
  const handleEdit = async (productId) => {
    navigate(`/admin/categoryv2/brand/product/${productId}`);
  };
  const handleDelete = async (productId) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this product? This action cannot be undone."
    );

    if (!userConfirmed) {
      return; // Exit if the user cancels
    }
    const accessToken = localStorage.getItem("adminaccessToken");
    if (!accessToken) {
      toast.error("Access token is missing. Please log in again.");
      setLoading(false);
      return;
    }
    try {
      await axios.delete(
        `https://api.gadgetfobia.com/api/v2/product/products/${productId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Include the token here
          },
        }
      );
      setProducts((prevProducts) =>
        prevProducts.filter((p) => p.id !== productId)
      );
      alert("Product deleted successfully!");
    } catch (err) {
      alert("Failed to delete product. Please try again.");
      console.error(err);
    }
  };

  return (
    <Layout>
      <div className="container-fluid">
        <AddProductv2 category={category} brand={brand} />

        {loading && <p className="text-center">Loading products...</p>}
        {error && <p className="text-danger text-center">Error: {error}</p>}

        {!loading && !error && products.length > 0 && (
          <div className="table-responsive">
            <strong>Products:{count}</strong>{" "}
            <table className="table table-bordered table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>Slider Images</th>
                  <th>Category</th>
                  <th>Brand</th>
                  <th>Model Name</th>
                  <th>Image</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product.id}>
                    <td>
                      {product.sliderImg && product.sliderImg.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap", // Wrap images to the next row
                            maxWidth: "150px", // Limit width to fit 5 images in a row (5 * 25px + spacing)
                          }}
                        >
                          {product.sliderImg.map((imgUrl, index) => (
                            <img
                              key={index}
                              src={imgUrl}
                              alt={`Sliderimage ${index + 1}`}
                              style={{
                                width: "25px",
                                height: "25px",
                                margin: "2px", // Spacing between images
                              }}
                            />
                          ))}
                        </div>
                      ) : (
                        <span>No images available</span>
                      )}
                    </td>

                    <td>{product.category}</td>
                    <td>{product.brand}</td>
                    <td>{product.model_name}</td>
                    <td>
                      <img
                        src={product.img}
                        alt={product.model_name}
                        style={{ width: "100px", height: "50px" }}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-success btn-sm "
                        onClick={() => handleEdit(product._id)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(product._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {!loading && !error && products.length === 0 && (
          <p className="text-center">No products found.</p>
        )}
      </div>
    </Layout>
  );
}
