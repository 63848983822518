import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { adminLogout } from "../../Service/adminService";

const Layout = ({ children }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const adminaccessToken = localStorage.getItem("adminaccessToken");

      if (!adminaccessToken) {
        console.log("Admin access token not found in local storage");
        return;
      }

      const response = await adminLogout(adminaccessToken);
      if (response.statusCode === 200) {
        localStorage.removeItem("adminaccessToken");
        localStorage.removeItem("adminrefreshToken");
        alert(response.message);
        navigate("/admin/auth");
      }
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  return (
    <div
      className="d-flex vh-100"
      style={{
        overflow: "hidden",
      }}>
      {/* Sidebar */}
      <nav
        className="d-flex flex-column col-12 col-md-3 col-lg-2 p-3"
        style={{
          backgroundColor: "black",
          color: "white",
          overflowY: "auto",
        }}>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link to="/admin" className="nav-link" style={{ color: "white" }}>
              Home
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              to="/admin/home-category"
              className="nav-link"
              style={{ color: "white" }}>
              Home Category
            </Link>
          </li> */}
          {/* <li className="nav-item">
            <Link
              to="/admin/product-category"
              className="nav-link"
              style={{ color: "white" }}>
              Product Category
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              to="/admin/categoryv2"
              className="nav-link"
              style={{ color: "white" }}>
              Products
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/admin/products-list"
              className="nav-link"
              style={{ color: "white" }}>
              Products List
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/admin/profilev2"
              className="nav-link"
              style={{ color: "white" }}>
              Profile
            </Link>
          </li>
          <li className="nav-item mt-3">
            <button onClick={handleLogout} className="btn btn-danger w-100">
              Logout
            </button>
          </li>
        </ul>
      </nav>

      {/* Main Content */}
      <main
        className="flex-grow-1 p-4"
        style={{
          backgroundColor: "black",
          color: "white",
          overflowY: "auto",
        }}>
        {children}
      </main>
    </div>
  );
};

export default Layout;
